// @ts-nocheck

import React, { FC, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Switch, Typography, Box, TextField } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridCellParams,
  GridActionsCellItem,
  gridClasses,
} from '@mui/x-data-grid';

import { BaseModal } from '@shared/components/BaseModal';
import type { GameKey } from '@shared/types';
import { exportFile } from '@shared/utils';

import { generateGameLink } from '../../Client/client.utils';
import ClipboardBtn from '../../Client/ClipboardBtn';
import { useExportGameKeys, useUpdateGameKeyField } from '../master.actions';

import ConfirmationDialog from './ConfirmationDialog';
import type { KeysTableProps } from './keys-table.types';

import styles from './index.module.scss';

const { tableHeaderContainer, exportLimitContainer } = styles;

const KeysTable: FC<KeysTableProps> = ({
  gameKeys = [],
  onDeleteClick,
  refetch,
}) => {
  const [localGameKeys, setLocalGameKeys] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedKey, setSelectedKey] = useState<string>('');
  const [exportLimit, setExportLimit] = useState<number>(0);
  const [isExportModalOpened, setIsExportModalOpened] = useState(false);

  const [_, updateGameKeyField] = useUpdateGameKeyField();
  const [exportResponse, exportGameKeys] = useExportGameKeys();

  useEffect(() => {
    if (gameKeys.length) {
      console.log('Loaded gameKeys:', gameKeys); // Лог здесь

      setLocalGameKeys(gameKeys);
    }
  }, [gameKeys]);

  useEffect(() => {
    if (exportResponse) {
      const exportGameKeysResponse = async () => {
        await exportFile(exportResponse, 'game_keys');
      };

      exportGameKeysResponse();
    }
  }, [exportResponse]);

  const handleDeleteKeyClick = (id: string) => {
    setSelectedKey(id);

    setOpen(true);
  };

  const handleUpdateGameKeyActiveStatus = async (
    id: string,
    isActive: boolean
  ) => {
    await updateGameKeyField(id, { isActive });

    setLocalGameKeys((previousGameKeys) => {
      const updatedGameKeys = previousGameKeys.map((gameKey) => {
        const { _id: currentGameKeyId } = gameKey;

        if (currentGameKeyId === id) {
          return { ...gameKey, isActive };
        }

        return gameKey;
      });

      return updatedGameKeys;
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteKeyConfirmationClick = () => {
    void onDeleteClick(selectedKey).then(() => refetch());

    setOpen(false);
  };

  const handleOpenExportModal = () => {
    setIsExportModalOpened(true);
  };

  const handleCloseExportModal = () => {
    setIsExportModalOpened(false);

    setExportLimit(0);
  };

  const onExportSubmit = async () => {
    await exportGameKeys(exportLimit);

    handleCloseExportModal();
  };

  const onLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExportLimit(event.target.value);
  };

  const rows = localGameKeys.map(
    ({
      gameName,
      key,
      _id,
      endEditDate,
      endGameDate,
      activateDate,
      isActive,
      runCount,
      gameBuildId,
      gameBuild,
      notion,
      firstName,
      email,
      isWhiteLabel,
    }) => ({
      gameName,
      key,
      endEditDate,
      endGameDate,
      activateDate,
      gameBuildId,
      gameBuild,
      isActive,
      runCount,
      notion,
      _id,
      firstName,
      email,
      isWhiteLabel,
    })
  );

  const columns: GridColDef[] = [
    {
      field: 'gameName',
      headerName: 'Game name',
      width: 120,
      editable: false,
    },
    {
      field: 'notion',
      headerName: 'Notion',
      flex: 1,
      minWidth: 250,
      editable: false,
    },
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'key',
      headerName: 'Key',
      minWidth: 300,
      flex: 1,
      editable: false,
    },
    {
      field: 'activateDate',
      headerName: 'Activate Date',
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (params: GridCellParams<Date | null>) => {
        const value =
          params.value === null
            ? 'Not activated'
            : dayjs(params.value).format('DD/MM/YYYY HH:mm:ss');
        const color = params.value === null ? 'red' : 'green';

        return (
          <Typography fontSize="14px" color={color}>
            {value}
          </Typography>
        );
      },
    },
    {
      field: 'endEditDate',
      headerName: 'End Edit Date',
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (params: GridCellParams<Date | null>) => {
        const value =
          params.value === null
            ? 'Not existing'
            : dayjs(params.value).format('DD/MM/YYYY HH:mm:ss');
        const color = params.value === null ? 'red' : 'green';

        return (
          <Typography fontSize="14px" color={color}>
            {value}
          </Typography>
        );
      },
    },
    {
      field: 'endGameDate',
      headerName: 'End Game Date',
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (params: GridCellParams<Date | null>) => {
        const value =
          params.value === null
            ? 'Not existing'
            : dayjs(params.value).format('DD/MM/YYYY HH:mm:ss');
        const color = params.value === null ? 'red' : 'green';

        return (
          <Typography fontSize="14px" color={color}>
            {value}
          </Typography>
        );
      },
    },
    {
      field: 'link',
      headerName: 'Link',
      Width: 50,
      editable: false,
      renderCell: (params: GridCellParams<GameKey>) => {
        const {
          row: { gameBuildId, gameBuild },
        } = params;
        const isGameBuildExists = !!gameBuild;

        return isGameBuildExists ? (
          <ClipboardBtn content={generateGameLink(gameBuildId, false)} />
        ) : (
          <Typography fontSize="14px" color="red">
            Not created
          </Typography>
        );
      },
    },
    {
      field: 'runCount',
      headerName: 'Run Count',
      width: 100,
      editable: false,
    },
    {
      field: 'isWhiteLabel',
      headerName: 'White label',
      minWidth: 300,
      flex: 1,
      editable: false,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      minWidth: 50,
      editable: false,
      renderCell: (params: GridCellParams<GameKey>) => {
        const {
          row: { isActive, _id },
        } = params;

        return (
          <Switch
            checked={isActive}
            onChange={(event) =>
              handleUpdateGameKeyActiveStatus(_id, event.target.checked)
            }
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      minWidth: 50,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete key"
          onClick={() => handleDeleteKeyClick(params.row._id)}
        />,
      ],
    },
  ];

  return (
    <>
      <Box>
        <Box className={tableHeaderContainer}>
          <Typography marginBottom="20px" variant="h5" noWrap component="div">
            Keys Data
          </Typography>
          <Button
            size="small"
            variant="contained"
            onClick={handleOpenExportModal}
          >
            Export Exel
          </Button>
        </Box>
      </Box>

      <DataGrid
        autoHeight={false}
        getRowId={(row) => row._id}
        rowCount={1}
        disableSelectionOnClick
        getRowHeight={() => 'auto'}
        rows={rows}
        sx={{
          height: 1200, // Фиксированная высота таблицы
          [`& .${gridClasses.cell}`]: {
            py: 1,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
          },
        }}
        columns={columns}
        scrollX
      />
      <ConfirmationDialog
        isOpen={open}
        onConfirm={handleDeleteKeyConfirmationClick}
        onClose={handleClose}
      />
      <BaseModal
        headerTitle="Export Exel"
        isOpened={isExportModalOpened}
        onSubmit={onExportSubmit}
        handleClose={handleCloseExportModal}
        buttonConfirmedText="Export"
      >
        <Box className={exportLimitContainer}>
          <TextField
            label="Enter limit"
            value={exportLimit}
            size="small"
            type="number"
            onChange={onLimitChange}
          />
        </Box>
      </BaseModal>
    </>
  );
};

export default KeysTable;
