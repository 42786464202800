import { Area } from 'react-easy-crop';

import dayjs from 'dayjs';

import type { GameBuild, Game } from '@shared/types';

import { getBaseUrl, API_BASE } from '@services/api';

import {
  readFile,
  getCroppedImg,
  createImage,
} from './ImageCropper/file-utils';

function filterCallback(
  image: File | string | undefined
): image is File | string {
  return !!image;
}

export function rotateImages(images: (File | string | undefined)[]) {
  const uploadedImages = images.filter(filterCallback);

  let rotatingIndex = 0;

  return images.map((image) => {
    if (image) {
      return image;
    }

    rotatingIndex === uploadedImages.length - 1
      ? (rotatingIndex = 0)
      : (rotatingIndex += 1);

    return uploadedImages[rotatingIndex];
  });
}

export const cropImage = async (image: string | File, croppedPixels: Area) => {
  let imageUrl;
  let fileName;
  // croppedPixels.x = 0, croppedPixels.y = 0, croppedPixels.height = 789, croppedPixels.width = 600

  if (typeof image === 'string') {
    imageUrl = image;
    fileName = image.split('/').pop() || image;
  } else {
    imageUrl = await readFile(image);
    fileName = image.name;
  }

  const canvasImage = await createImage(imageUrl);
  console.log('cropImage);', fileName, croppedPixels);
  return getCroppedImg(canvasImage, croppedPixels, fileName);
};


export const resizeImage = (file: File, maxWidth: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const url = URL.createObjectURL(file);

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const scaleFactor = maxWidth / image.width;
      canvas.width = maxWidth;
      canvas.height = image.height * scaleFactor;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          } else {
            reject(new Error('Canvas is empty'));
          }
          URL.revokeObjectURL(url);
        },
        file.type,
        1
      );
    };

    image.onerror = (error) => {
      reject(error);
      URL.revokeObjectURL(url);
    };

    image.src = url;
  });
};



const resizeImageToWidth = (image: HTMLImageElement, maxWidth: number): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const scaleFactor = maxWidth / image.width;

    const canvas = document.createElement('canvas');
    canvas.width = Math.round(maxWidth);
    canvas.height = Math.round(image.height * scaleFactor);

    const ctx = canvas.getContext('2d', { alpha: false }); // Отключаем прозрачность

    if (!ctx) {
      reject(new Error('Failed to get canvas context'));
      return;
    }

    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL('image/jpeg', 0.8); // Сохраняем как JPEG

    const resizedImage = new Image();
    resizedImage.crossOrigin = 'anonymous'; // Устанавливаем CORS
    resizedImage.onload = () => resolve(resizedImage);
    resizedImage.onerror = () => reject(new Error('Failed to load resized image'));
    resizedImage.src = dataUrl;
  });
};




export const getMusicUrl = (
  gameBuild: GameBuild,
  existingGameBuild: GameBuild,
  gameDefaults: Game
) => {
  if (gameBuild) {
    return gameBuild.musicUrl;
  }

  if (existingGameBuild) {
    return existingGameBuild.musicUrl;
  }

  if (gameDefaults) {
    return gameDefaults.defaultMusicUrl;
  }

  return '';
};

export const generateGameLink = (buildId: string, isTesting: boolean) => {
  return `${getBaseUrl()}/game?buildId=${buildId}${
    isTesting ? '&isTesting=true' : ''
  }`;
};

export const getEndEditDate = (
  activateDate: Date | null,
  editDayCount: number
) => dayjs(activateDate).add(editDayCount, 'day').format('DD/MM/YYYY HH:mm:ss');

export const getIsClientFormDisabled = (
  activateDate: Date | null,
  editDayCount: number
) => {
  if (activateDate === null) {
    return false;
  }

  return dayjs(activateDate).add(editDayCount, 'day').isBefore(dayjs());
};
